import React from 'react';
import './App.css';
import Card from './components/Card';
import useScrollFade from './hooks/useScrollFade';
import cardContent from './data/cardContent';

function App() {
  const { visibleCardIndex, setCardRef } = useScrollFade(cardContent.length);

  return (
    <div className="App bg-[#F0EFE9] min-h-screen">
      <div className="container mx-auto px-4 py-8">
        {cardContent.map((card, index) => (
          <div
            key={index}
            ref={setCardRef(index)}
            className="mb-12 first:mt-[5vh] last:mb-[35vh]"
          >
            <Card
              {...card}
              isVisible={index === visibleCardIndex}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
