import { useState, useEffect, useRef } from 'react';

const useScrollFade = (totalCards: number) => {
  const [visibleCardIndex, setVisibleCardIndex] = useState(0);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const hasScrolled = useRef(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled.current) {
        hasScrolled.current = true;
      }

      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      cardRefs.current.forEach((cardRef, index) => {
        if (cardRef) {
          const cardTop = cardRef.offsetTop;
          const cardHeight = cardRef.offsetHeight;
          const cardCenter = cardTop + cardHeight / 2;

          const distanceFromCenter = Math.abs(scrollY + windowHeight / 2 - cardCenter);
          const threshold = windowHeight / 4;

          if (distanceFromCenter < threshold) {
            setVisibleCardIndex(index);
          }
        }
      });
    };

    // Initial visibility check with a delay
    const initialCheckTimeout = setTimeout(() => {
      handleScroll();
    }, 100);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(initialCheckTimeout);
    };
  }, []);

  const setCardRef = (index: number) => (element: HTMLDivElement | null) => {
    cardRefs.current[index] = element;
  };

  // Only change visibility if the user has scrolled
  const effectiveVisibleCardIndex = hasScrolled.current ? visibleCardIndex : 0;

  return { visibleCardIndex: effectiveVisibleCardIndex, setCardRef };
};

export default useScrollFade;