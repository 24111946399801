export interface CardData {
    title: string;
    content: string;
    imageSrc?: string;
    videoSrc?: string;
    buttons?: { text: string; url: string }[];
  }
  
  const cardContent: CardData[] = [
    {
      title: "Hello Anthropic Hiring Team 👋",
      content: "My name is Patrick Herbert. I'm applying for the Design Engineer position. I reside in Spokane, Washington with my wife and son.",
      imageSrc: "Patrick_Sailboat.jpg",
    },
    {
      title: "What I Do Currently",
      content: "I work remote as the Director of Media Development for a small company (<15 people) called The Center for Personal Protection and Safety. In my role I design and develop video-based eLearning workplace violence safety training for large companies. I wear many hats to include storyboarding, video editing, animation, graphic design, project management, and increasingly coding.",
      videoSrc: "https://www.youtube.com/embed/sMCK6aAwyIY?si=4SE62WiyRH501TsI",
    },
    {
      title: "Where I Studied",
      content: "I earned a B.S. degree in Computer Science at Whitworth University in 2017. The critical thinking skills instilled during this time have benefited my life since, even if my focus hasn't involved programming till more recently.",
      imageSrc: "Whitworth.png",
    },
    {
      title: "My Career: Making Safety Engaging",
      content: "I've worked at CPPS since 2017. First as an intern, then as a full-time eLearning Developer and now in a salary leadership role as Director of Media Development. Despite my department being very small (4 people), the courses we create receive high compliments from our clients, which include Fortune 100 companies. The image above is feedback I received from a UX Designer at a large (name-redacted) company.",
      imageSrc: "Quote2.png",
    },
    {
      title: "My Evenings: Coding With Claude",
      content: "More recently, I've been using my free time to develop a Claude powered macOS app called \"Code Stuff\" to streamline context management and automate multi-file editing. I'm designing UX, prompt engineering and programming in Swift, with the goal of making coding intelligence more accessible to non-coders. This video is a quick screen capture of me using my app to prototype this portfolio page.",
      videoSrc: "https://player.vimeo.com/video/1004723733?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      title: "Tomorrow: Design Engineer",
      content: "I read the online posting for Design Engineer and decided to apply. My work experience may be a bit left field, however directionally I feel I am a meaningful fit. I've listened to Joel Lewenstein's interview on Academy where he describes the sort of novel challenges the Anthropic Design team is working to solve. Raising the ceiling and lowering the floor from a design/UX perspective aligns with where I would like to see my career go. I would be grateful for the opportunity to interview. Cheers!",
      buttons: [
        { text: "LinkedIn", url: "https://www.linkedin.com/in/patrick-thomas-herbert/" },
        { text: "Email Me", url: "mailto:p@trickherbert.com" },
      ],
    },
  ];
  
  export default cardContent;