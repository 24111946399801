
import React from 'react';

interface CardProps {
  title: string;
  content: string;
  imageSrc?: string;
  videoSrc?: string;
  buttons?: { text: string; url: string }[];
  isVisible: boolean;
}

const Card: React.FC<CardProps> = ({ title, content, imageSrc, videoSrc, buttons, isVisible }) => {
  return (
    <div
    className={`
    max-w-xl mx-auto bg-white rounded-2xl shadow-sm p-8
    transition-all duration-500 ease-in-out transform
    ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}
    hover-hover:hover:scale-105
  `}
      >
      {imageSrc && (
        <img src={imageSrc} alt={title} className="w-full h-auto mb-4 rounded-2xl" />
      )}
      
      {videoSrc && (
        <div className="aspect-w-16 aspect-h-9 mb-4">
        <iframe
        src={videoSrc}
        title={`Video: ${title}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="w-full h-full rounded-2xl"
        ></iframe>
        </div>
      )}
      
      <h2 className="text-2xl font-bold mb-4 text-left text-[#141413]">{title}</h2>
      
      <p className="text-left text-[#141413] font-normal mb-0">{content}</p>
      
      {buttons && buttons.length > 0 && (
        <div className="flex flex-wrap gap-4">
        {buttons.map((button, index) => (
          <a
          key={index}
          href={button.url}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-[#141413] text-white font-normal mt-6 py-2 px-4 rounded transition-colors duration-300 hover:bg-[#565656]"
          >
          {button.text}
          </a>
        ))}
        </div>
      )}
      </div>
    );
  };
  
  export default Card;
  